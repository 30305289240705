import gql from 'graphql-tag';

export const UPDATE_USER = gql`
mutation updateUser(
  $username: String,
  $firstName: String,
  $lastName: String,
  $bio: String,
  $ethnicity: EthnicityOption,
  $gender: GenderOption,
  $birthday: Birthday,
  $zipcode: String,
) {
  updateUser(
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    bio: $bio,
    ethnicity: $ethnicity,
    gender: $gender,
    birthday: $birthday,
    zipcode: $zipcode,
  ) {
    username
    email
    slug
    id
    bio
  }
}
`;

// TODO NEXUS-6312
export const UPDATE_EMAIL = gql`
mutation updateEmail($clearToken: Boolean, $token: String) {
  updateEmail(clearToken: $clearToken, token: $token) {
    isUpdatingEmail
    slug
    email
  }
}
`;

export const UPDATE_GENERAL_SETTINGS = gql`
mutation updateGeneralSettings(
  $username: String,
  $subscribed: Boolean,
  $email: String,
) {
  updateGeneralSettings(
    username: $username,
    subscribed: $subscribed,
    email: $email,
  ) {
    id
    username
    email
    isUpdatingEmail
    slug
    receiveEmails
  }
}
`;

export const UNSUBSCRIBE = gql`
mutation unsubscribe($email: Email!, $hash: String, $legacyHash: String) {
  unsubscribe(email: $email, hash: $hash, legacyHash: $legacyHash)
}
`;

export const DELETE_USER = gql`
mutation delete_user {
  delete_user
}
`;

export const DELETE_PROFILE_PICTURE = gql`
mutation deleteProfilePicture {
  deleteProfilePicture
}
`;

export const RESEND_EMAIL_VERIFICATION = gql`
mutation resendEmailVerification($verificationRedirect: String) {
  resendEmailVerification(verificationRedirect: $verificationRedirect)
}`;

export const SET_PHONE = gql`
mutation setPhone($phoneNumber: PhoneNumber!) {
  setPhone(phoneNumber: $phoneNumber)
}
`;

export const RESEND_PHONE_VERIFICATION = gql`
mutation resendPhoneVerification {
  resendPhoneVerification
}`;

export const VERIFY_PHONE = gql`
mutation verifyPhone($code: String!) {
  verifyPhone(code: $code)
}
`;

export const SET_DISORDER_RELATIONSHIP = gql`
mutation setDisorderRelationship($disorderId: NexusId, $relationship: DisorderRelationship) {
  setDisorderRelationship(disorderId: $disorderId, relationship: $relationship)
}
`;

export const UPDATE_USER_PRIVACY_SETTING = gql`
mutation updateUserPrivacySetting($name: String!, $isPrivate: Boolean!) {
  updateUserPrivacySetting(name: $name, isPrivate: $isPrivate) {
    id
    name
    isPrivate
  }
}
`;

export const DELETE_USER_DISORDER = gql`
mutation deleteUserDisorder($id: NexusId!) {
  deleteUserDisorder(id: $id)
}
`;

export const SET_SOCIAL_SITES = gql`
mutation setSocialSites($socialSites: [NewSocialSite!]) {
  setSocialSites(socialSites: $socialSites)
}
`;

export const CREATE_SOCIAL_SITE = gql`
mutation createSocialSite($type: SocialSiteType!, $url: String!) {
  createSocialSite(type: $type, url: $url) {
    id
  }
}`;

export const UPDATE_SOCIAL_SITE = gql`
mutation updateSocialSite($id: NexusId!, $type: String!, $url: String!) {
  updateSocialSite(id: $id, type: $type, url: $url)
}`;

export const DELETE_SOCIAL_SITE = gql`
mutation deleteSocialSite($id: NexusId!) {
  deleteSocialSite(id: $id)
}`;
