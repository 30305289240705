export default {
  methods: {
    insertQualtricsParams(content) {
      if (process.browser && this.$store.state.currentUser.loggedIn) {
        try {
          // Regex is that URL with anything after it up to but not including a ", ',
          // >, a space, or the end of the string
          const pattern = new RegExp('(https://healthunion.az1.qualtrics.com.*?(?=["\'> ]|$))');
          const result = pattern.exec(content);
          const url = new URL(result[1]);
          url.searchParams.append('ac_id', this.$store.state.currentUser.user.acId);
          url.searchParams.append('ac_hash', this.$store.state.currentUser.user.sha256Email);
          return content.replace(result[1], url.href);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          return content;
        }
      }
      return content;
    },
  },
};
