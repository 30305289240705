<template>
  <div class="lead-gen-pop-up" data-test-id="lead-gen-pop-up">
    <component
      :is="openInDialog ? 'dialog-base' : 'pop-over-base'"
      :class="{
        'lead-gen-pop-up__base--success' : userSignedUp && !openInDialog,
        'lead-gen-pop-up__base' : !openInDialog ,
        'lead-gen-pop-up__dialog-base' : openInDialog
      }"
      @close="close"
    >
      <div v-if="!userSignedUp" key="pending" class="lead-gen-pop-up__content">
        <div class="lead-gen-pop-up__image-container">
          <img
            src="/common/nexus/subscription-pop-up.png"
            alt=""
            class="lead-gen-pop-up__image"
          >
        </div>
        <form-component
          class="lead-gen-pop-up__form"
          data-test-id="lead-gen-pop-up__form"
          method="post"
          @submit.prevent="handleSubscribe"
        >
          <label
            class="lead-gen-pop-up__label heading heading--3"
            data-test-id="lead-gen-pop-up__heading"
            :for="id"
          >
            Stay in the Know
          </label>
          <p
            class="lead-gen-pop-up__text"
            data-test-id="lead-gen-pop-up-text"
          >
            Get the latest from our community and Health Leaders.
          </p>
          <div class="lead-gen-pop-up__input-container">
            <text-input
              :id="id"
              v-model="email"
              exclude-label
              placeholder="Enter your email"
              input-type="email"
              required
              name="email"
              class="lead-gen-pop-up__input text-input--full-width"
              data-test-id="lead-gen-pop-up__input"
            />
            <button-component
              class="lead-gen-pop-up__button"
              data-gtm_category="lead_gen"
              data-gtm_action="form_submit"
              data-gtm_label="lead_gen_shortcode"
              button-type="subscribe to newsletter"
              data-test-id="lead-gen-pop-up__cta-button"
            >
              Sign up
            </button-component>
          </div>
          <p
            class="lead-gen-pop-up__disclaimer disclaimer-text"
            data-test-id="lead-gen-pop-up__disclaimer-text"
          >
            By providing your email address, you are agreeing to our
            <a
              href="https://health-union.com/privacy-policy/"
              target="_blank"
              rel="noopener"
            >Privacy Policy</a>
            and
            <a
              href="https://health-union.com/terms-of-use/"
              target="_blank"
              rel="noopener"
            >Terms of Use</a>.
          </p>
        </form-component>
      </div>
      <div v-else key="success" class="lead-gen-pop-up__content" data-test-id="lead-gen-pop-up__success-content">
        <div class="lead-gen-pop-up__image-container">
          <img
            src="/common/nexus/subscription-pop-up-success.png"
            alt=""
            rel="preload"
            class="lead-gen-pop-up__image lead-gen-pop-up__image--success"
            data-test-id="lead-gen-pop-up__success-image"
          >
        </div>
        <div class="lead-gen-pop-up__success-text" data-test-id="lead-gen-pop-up__success-text">
          <h3>Thanks for signing up!</h3>
          <p>Watch your inbox for the latest articles and more.</p>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import PopOverBase from '@/components/Global/PopOverBase.vue';
import DialogBase from '@/components/Global/DialogBase.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import ButtonComponent from '@/components/Global/ButtonComponent.vue';
import { GENERATE_LEAD } from '@/graphql/mutations/lead-gen-mutations';
import dataLayerMixin from '@/mixins/data-layer-mixin';
import { mapGetters } from 'vuex';

export default {
  name: 'LeadGenPopUp',
  components: {
    TextInput,
    ButtonComponent,
    PopOverBase,
    DialogBase,
  },
  mixins: [dataLayerMixin],
  props: {
    openInDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      userSignedUp: false,
      id: 'leadgen-email-address',
      medium: 'Site',
    };
  },
  computed: {
    ...mapGetters(['engagementSubWallIsActive']),
    popUpTime() {
      if (this.$site.settings.subscription_pop_up_time_based) return 30;
      return this.$store.getters.getVariantKey('lead_gen_pop_up_timing');
    },
    campaignBasedOnContainer() {
      if (this.openInDialog) {
        return this.engagementSubWallIsActive ? 'subscription wall - polls' : 'nav subscribe';
      }

      return `subscription pop up - ${this.popUpTime} seconds`;
    },
    formBasedOnContainer() {
      if (this.openInDialog) {
        return this.engagementSubWallIsActive ? 'subscription wall' : 'nav subscribe';
      }
      return 'subscription pop up';
    },
  },
  mounted() {
    this.trackPopUpImpression();
  },
  methods: {
    close() {
      if (!this.openInDialog) {
        this.$store.dispatch('setHasLeadGenPopUpDisabledCookie');
      }
      this.$store.dispatch('setEngagementSubWallStatus', false);
      this.$emit('close');
    },
    handleSubscribe() {
      this.$apollo.mutate({
        mutation: GENERATE_LEAD,
        variables: {
          campaign: this.campaignBasedOnContainer,
          email: this.email,
          form: this.formBasedOnContainer,
          pageviewId: this.$store.getters.pageViewId,
          medium: this.medium,
        },
      }).then(() => {
        this.userSignedUp = true;
        this.$store.dispatch('setHasLeadGenPopUpDisabledCookie');
      }).catch(() => {
        this.$logger.warn('problem submitting new lead.');
      });

      this.$store.dispatch('setUserHasSubscribed', true);

      this.setDataLayer();
      this.pushDataLayer();
      this.setTrackingInAC(this.email);

      this.$gtm.trackEvent({
        event: 'nexus.event',
        action: 'form_submit',
        label: 'lead_gen_subscription_pop_up',
      });
    },
    trackPopUpImpression() {
      let popUpSource;
      if (this.$site.settings.subscription_pop_up_time_based) {
        popUpSource = 'Site Setting';
      } else if (this.$store.getters.getVariantKey('lead_gen_pop_up_timing')) {
        popUpSource = 'A/B Test';
      }
      this.$snowplow.trackSubscriptionPopUpImpression(this.popUpTime, popUpSource);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_lead-gen-pop-up';
</style>
